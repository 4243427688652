<!-- Mat loader starts -->
<div class="page_loader" *ngIf="isLoading">
  <!-- <mat-spinner></mat-spinner> -->
  <div class="text-loader">
    <img src="../../assets/images/svg/logo-icon.svg" alt="logo icon" />
    <div class="word"></div>
  </div>
</div>
<!-- Mat loader starts -->

<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
  <div class="outer-container">
    <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster" />
  </div>
  <div class="inner-container">
    <h3>Error</h3>
    <p>{{ showErrorMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
  <div class="outer-container">
    <img
      src="../../assets/images/svg/success-toaster-icon.svg"
      alt="warning toaster"
    />
  </div>
  <div class="inner-container">
    <h3>Success</h3>
    <p>{{ showSuccessMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<!-- warning toast ends -->

<!-- <div class="header_block">
    <app-header></app-header>
</div> -->

<div class="global-search-section new-global-search" *ngIf="!isLoading">
  <form>
    <div class="search-query">
      <div class="tqls-search">
        <div class="search-input">
          <textarea
            [rows]="rows"
            #searchText
            class="main-page-input gb-search-ipt"
            [(ngModel)]="searchQuery"
            (input)="onChange($event, 'search_query')"
            autocomplete="off"
            [placeholder]="placeholderText"
            spellcheck="true"
            name="searchQuery"
            (clickOutside)="onOutsideClick()"
            [formControl]="email"
            (keydown)="preventEnterKey($event)"
          ></textarea>
          <button *ngIf="isAssertionFocused" [ngClass]="searchSuggestions.length>0 ?'textsuggestions':'textclear'" (click)="clearQuery()"><mat-icon aria-hidden="false" aria-label="Example close icon" fontIcon="close"><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="512px" class="closeicon" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/></svg></mat-icon></button>
          <button
            class="go-search"
            (click)="createAnalysis()"
            *ngIf="searchSuggestions.length > 0"
          >
            Go<em>!</em>
          </button>
        </div>
        <div class="auto-suggitions" *ngIf="searchSuggestions.length > 0">
          <div class="auto-suggest-scroll">
            <ul>
              <li *ngFor="let suggestion of searchSuggestions">
                <div class="suggest-item clearfix">
                  <div
                    class="suggested-text"
                    (click)="setSearchQuery(suggestion.assertion_text)"
                  >
                    <span class="highlighted">{{
                      suggestion.assertion_text
                    }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="search-v-c">
        <ul>
          <li>
            <!-- <div class="voice-block"><a (click)="startListening()"><img src="../../assets/images/svg/voice-icon.svg" alt="voice search" /></a></div> -->
            <span *ngIf="isListen" class="loading-dots">
              <span class="dot">.</span>
              <span class="dot">.</span>
              <span class="dot">.</span>
              <span class="dot">.</span>
            </span>
            <button *ngIf="searchSuggestions.length===0" class="go-search" (click)="createAnalysis()">
              Go<em>!</em>
            </button>
            <!-- [disabled]="!searchQuery" -->
          </li>
        </ul>
      </div>
    </div>

    <div class="or-divider" *ngIf="planTypeFlag">OR</div>

    <div class="upload_files" *ngIf="planTypeFlag">
      <input
        #Image
        type="file"
        (change)="handleFileInput($event.target.files)"
      />
      <div class="inn_upload_file_block">
        <div class="upload-icon">
          <img
            src="../../assets/images/svg/upload-file-icon.svg"
            alt="upload icon"
          />
        </div>
        <div class="up-formats">
          <h3>Click here to upload a document</h3>
          <!-- <p>Files allowed: .txt, .docx, .pdf, .mp3, .mp4, .wav</p> -->
          <p>Files allowed: .doc, .docx, .txt, .mp4, .mp3</p>
        </div>
      </div>
      <!---- Uploaded Image Preview ---->
      <!-- <div class="image-prev">
                <img width="100%" *ngIf="imageUrl" [src]="imageUrl" class="image">
                <button>Upload</button>
            </div> -->
    </div>

    <div class="uploaded_history">
      <div class="history-title-sec">
        <h1>History</h1>
        <img
          class="refresh-dim"
          src="../../assets/images/svg/refresh-icon.svg"
          alt="refresh-arrow"
          (click)="refreshDocs()"
          *ngIf="planTypeFlag"
        />
      </div>
      <!-- File upload loader -->
      <div class="h-up-block uploading-loader" *ngIf="fileUploadLoader">
        <div *ngFor="let load of historyLoaderArray">
          <div class="file-name">
            <div class="loader-container">
              <div class="animated-background"></div>
            </div>
          </div>
          <div class="file-type">
            <div class="loader-container">
              <div class="animated-background"></div>
              <div class="animated-background"></div>
              <div class="animated-background"></div>
            </div>
          </div>
          <div class="file-name">
            <div class="loader-container">
              <div class="animated-background"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- File upload loader -->

      <!-- <div class="h-uploaded-files" *ngIf="!fileUploadLoader">
                <div class="history-slider" *ngIf="planTypeFlag">
                    <div *ngFor="let docs of fileDocuments" class="h-up-block item">
                        <div class="processed" *ngIf="docs.status == 'completed' && planTypeFlag">
                            <div class="upload_status">Processed</div>
                                <div class="delect-file" (click)="deleteFile(docs._id)"><img src="../../assets/images/svg/history-delete-icon.svg" alt="delete files" /></div>
                                <div (click)="redirectToResult(docs._id, docs.status, docs)">
                                    <div class="file-type">
                                        <p>{{docs.previewText}}</p>
                                    </div>
                                    <div class="file-name">
                                        <h3>{{(docs.fileName.split('.')[0]).slice(0, 12)}}..</h3>&nbsp;<span>{{docs.fileName.split('.')[1]}}</span>
                                    </div>
                                </div>
                        </div>
                        <div class="processing" *ngIf="docs.status != 'completed' && planTypeFlag">
                        <div class="upload_status">{{docs.status | titlecase}}.. <span *ngIf="(docs.progress || docs.progress >= 0 || docs.progress == 0) && docs.status == 'processing'">({{docs.progress}}%)</span></div>
                            <div class="delect-file" (click)="deleteFile(docs._id)"><img src="../../assets/images/svg/history-delete-icon.svg" alt="delete files" /></div>
                                <div (click)="redirectToResult(docs._id, docs.status, docs)">
                                    <div class="file-type">
                                        <p>{{docs.previewText}}</p>
                                    </div>
                                    <div class="file-name">
                                        <h3>{{(docs.fileName.split('.')[0]).slice(0, 12)}}..</h3>&nbsp;<span>{{docs.fileName.split('.')[1]}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="history-slider" *ngIf="!planTypeFlag">
                    <div *ngFor="let docs of userHistory" class="h-up-block item">
                        <div class="processed">
                            <div class="upload_status">Processed</div>
                                <div class="delect-file" (click)="deleteHistoryQuery(docs)"><img src="../../assets/images/svg/history-delete-icon.svg" alt="delete files" /></div>
                                <div (click)="redirectToQuery(docs)">
                                    <div class="file-type">
                                        <p>{{docs}}</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div> -->

      <div class="h-uploaded-files" *ngIf="!fileUploadLoader">
        <div class="history-slider" *ngIf="planTypeFlag">
          <owl-carousel-o [options]="historySlider">
            <ng-container
              *ngFor="let docs of fileDocuments"
              class="h-up-block item"
            >
              <ng-template carouselSlide
                ><!-- [width]="196" -->
                <div class="h-up-block">
                  <div class="processed" *ngIf="docs.status == 'completed'">
                    <div class="upload_status">Processed</div>
                    <div class="delect-file" (click)="deleteFile(docs._id)">
                      <img
                        src="../../assets/images/svg/history-delete-icon.svg"
                        alt="delete files"
                      />
                    </div>
                    <div
                      (click)="redirectToResult(docs._id, docs.status, docs)"
                    >
                      <div class="file-type">
                        <p>{{ docs.previewText }}</p>
                      </div>
                      <div class="file-name">
                        <h3>
                          {{ docs.fileName.split(".")[0].slice(0, 12) }}..
                        </h3>
                        &nbsp;<span>{{ docs.fileName.split(".")[1] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="h-up-block">
                  <div class="processing" *ngIf="docs.status != 'completed'">
                    <div class="upload_status">
                      {{ docs.status | titlecase }}..
                      <span
                        *ngIf="
                          (docs.progress ||
                            docs.progress >= 0 ||
                            docs.progress == 0) &&
                          docs.status == 'processing'
                        "
                        >({{ docs.progress }}%)</span
                      >
                    </div>
                    <div class="delect-file" (click)="deleteFile(docs._id)">
                      <img
                        src="../../assets/images/svg/history-delete-icon.svg"
                        alt="delete files"
                      />
                    </div>
                    <div
                      (click)="redirectToResult(docs._id, docs.status, docs)"
                    >
                      <div class="file-type">
                        <p>{{ docs.previewText }}</p>
                      </div>
                      <div class="file-name">
                        <h3>
                          {{ docs.fileName.split(".")[0].slice(0, 12) }}..
                        </h3>
                        &nbsp;<span>{{ docs.fileName.split(".")[1] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>

        <div class="history-slider" *ngIf="!planTypeFlag">
          <owl-carousel-o [options]="historySlider">
            <ng-container
              *ngFor="let docs of userHistory"
              class="h-up-block item"
            >
              <ng-template carouselSlide>
                <div class="h-up-block">
                  <div class="processed">
                    <div class="upload_status">Processed</div>
                    <div class="delect-file" (click)="deleteHistoryQuery(docs)">
                      <img
                        src="../../assets/images/svg/history-delete-icon.svg"
                        alt="delete files"
                      />
                    </div>
                    <div (click)="redirectToQuery(docs)">
                      <div class="file-type">
                        <p>{{ docs }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </form>

  <!-- searched history -->
  <div class="suggitions-history" *ngIf="userHistory.length > 0">
    <h3>History</h3>
    <div class="auto-suggest-scroll">
      <ul>
        <li *ngFor="let hist of userHistory">
          <div class="suggest-item clearfix">
            <div class="suggested-text" (click)="openHistory(hist)">
              <span class="highlighted">{{ hist }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="suggitions-history" *ngIf="userHistory.length == 0"></div>

  <div class="footer_logo">
    <img src="../../assets/images/tqllc-footer-logo.png" alt="footer logo" />
  </div>
</div>

<!-- <div class="tqllc-version">{{productVersion}}</div> -->
