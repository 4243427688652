import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import * as Highcharts from 'highcharts';
import HC_noDataModule from 'highcharts/modules/no-data-to-display';
import customPlugin from './customPlugin';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalmodalpopupComponent } from '../globalmodalpopup/globalmodalpopup.component';
import { ModalPopupService } from '../services/modalPopup.services';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';

HC_noDataModule(Highcharts);
customPlugin(Highcharts);

interface citation {
  value: string;
  viewValue: string;
}
declare var $: any;

@Component({
  selector: 'app-main-engine',
  templateUrl: './main-engine.component.html',
  styleUrls: ['./main-engine.component.scss'],
})
export class MainEngineComponent implements OnInit {
  owlSlider: OwlOptions = {
    loop: false,
    margin: 40,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    autoWidth: true,
    navSpeed: 700,
    // rewind: true,
    slideBy: 1,
    mergeFit: false,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 2,
      },
      1100: {
        items: 2,
      },
    },
    nav: true,
  };

  @Input() searchResp: any = {};
  @Input() loading: boolean;

  citations: citation[] = [
    { value: 'apa', viewValue: 'APA' },
    { value: 'mla', viewValue: 'MLA' },
    { value: 'chicago', viewValue: 'Chicago' },
  ];
  Highcharts: typeof Highcharts = Highcharts;

  // Loader & Error Handling Variables
  showSuccessToaster: boolean = false;
  showSuccessMsg: string = '';
  initialLoad = true;
  showErrorToaster: boolean = false;
  showErrorMsg: string = '';
  isLoading: boolean = true;
  general_text: string;
  answerBox: any = '';
  labelText: string = '';
  pvgprogress = 0;
  newsAgencies: any = [];
  favnewsicons: any = [];
  moreCont: boolean = false;
  selected: any = '';
  enableRanking: boolean = false;
  enableAuthor: boolean = false;
  dialogRef: MatDialogRef<any>;
  selectedCheckBoxes: any = new Set([]);
  supportingUrls: any = {
    results: [],
    total_count: 0,
    final_count: '',
  };
  debunkingUrls: any = {
    results: [],
    total_count: 0,
    final_count: '',
  };
  factCheckUrls: any = {
    results: [],
    total_count: 0,
  };
  wikiUrls: any = {
    results: [],
    total_count: 0,
    result_count: '0.0',
  };
  env: string = environment.env;
  planType: string = '';
  planTypeFlag: boolean = true;

  constructor(
    private router: Router,
    public userService: UserService,
    private http: HttpClient,
    public dialog: MatDialog,
    private modalPopupService: ModalPopupService,
    private sanitizer: DomSanitizer
  ) {
    this.planType = localStorage.getItem('plan_name');
    if (this.planType == 'Legit™ Lite') {
      this.planTypeFlag = false;
    }
    if (this.planType == 'Legit™ Professional') {
      this.planTypeFlag = false;
    }
    if (this.planType == 'Free Trial') {
      this.planTypeFlag = false;
    }
  }

  openDialog(option: any) {
    this.modalPopupService.setPopup(option);
    setTimeout(() => {
      this.dialogRef = this.dialog.open(GlobalmodalpopupComponent, {
        panelClass: ['global-modal-popup', 'animate__animated'],
      });

      this.dialogRef.disableClose = true;
    }, 200);
  }

  ngOnInit(): void {
    if (this.loading) this.isLoading = this.loading;

    setTimeout(() => {
      this.runAllFuncs(false);
      this.userService.appLoading$.subscribe((appLoad) => {
        if (appLoad) {
          this.runAllFuncs(true);
        } else {
          this.runAllFuncs(false);
        }
      });
    }, 1000);

    // setTimeout(() => {
    // 	this.loadSliderCode();
    // }, 1500);

    $('.modal').each(function (l) {
      $(this).on('show.bs.modal', function (l) {
        var o = $(this).attr('data-easein');
        'shake' == o
          ? $('.modal-dialog').velocity('callout.' + o)
          : 'pulse' == o
          ? $('.modal-dialog').velocity('callout.' + o)
          : 'tada' == o
          ? $('.modal-dialog').velocity('callout.' + o)
          : 'flash' == o
          ? $('.modal-dialog').velocity('callout.' + o)
          : 'bounce' == o
          ? $('.modal-dialog').velocity('callout.' + o)
          : 'swing' == o
          ? $('.modal-dialog').velocity('callout.' + o)
          : $('.modal-dialog').velocity('transition.' + o);
      });
    });

    this.userService.data$.subscribe((data) => {
      this.enableRanking = data.enableRanking;
      this.enableAuthor = data.enableAuthor;
      if (!this.initialLoad) {
        if (this.enableAuthor && this.enableRanking) {
          this.openToaster(true, false, 'Demo check is enabled');
        } else {
          this.openToaster(true, false, 'Demo check is disabled');
          this.showLabels();
        }
      }
      this.initialLoad = false;
    });
  }

  ngOnChanges(changes: any): void {
    if (changes.searchResp?.previousValue !== undefined) {
      this.isLoading = true;
      setTimeout(() => {
        this.runAllFuncs(false);
      }, 1000);

      // setTimeout(() => {
      // 	this.loadSliderCode();
      // }, 1500);
    }
  }

  showLabels() {
    const authorizedEmails = [
      'gaurav@maxiomtech.com',
      'mohsinec317@gmail.com',
      'jhnatio@jgpis.org',
      'george.vernidub@maxiomtech.com',
      'andrewmeiners@tqllc.company',
      'andrewameiners@gmail.com',
      'antonio@maxiomtech.com',
      'bujare@maxiomtech.com',
      'bujare@inspectorit.com',
      'bruce.h.becker@truthquotient.co',
      'bruce.h.becker@gmail.com',
      'bruce.h.becker@tqllc.company',
    ];
    let useremail = localStorage.getItem('email') || '';
    if (authorizedEmails.includes(useremail)) {
      this.enableAuthor = false;
      this.enableRanking = false;
    } else {
      this.enableAuthor = true;
      this.enableRanking = true;
    }
  }

  runAllFuncs(loadState: boolean) {
    this.isLoading = loadState;
    this.setSearchResponseData();
  }

  // loadSliderCode() {
  // 	$('.slick-slider').on('init', (event, slick, currentSlide) => {
  // 		let slideIndex = slick.currentSlide;
  // 		let slidesLength = slick.slideCount;
  // 	})

  // 	setTimeout(() => {
  // 		$('.slick-slider').slick({
  // 			slidesToShow: 2,
  // 			dots: false,
  // 			autoplay: false,
  // 			arrows: true,
  // 			infinite: false,
  // 			speed: 500,
  // 			centerMode: false,
  // 			variableWidth: false,
  // 			draggable: false,
  // 			responsive: [{
  // 				breakpoint: 1024,
  // 				settings: {
  // 					slidesToShow: 2,
  // 					slidesToScroll: 1,
  // 				}
  // 			}, {
  // 				breakpoint: 1024,
  // 				settings: {
  // 					slidesToShow: 2,
  // 					slidesToScroll: 1,
  // 				}
  // 			}, {
  // 				breakpoint: 800,
  // 				settings: {
  // 					slidesToShow: 1,
  // 					slidesToScroll: 1,
  // 					infinite: true,

  // 				}
  // 			}, {
  // 				breakpoint: 600,
  // 				settings: {
  // 					slidesToShow: 1,
  // 					slidesToScroll: 1,
  // 					infinite: true,

  // 				}
  // 			}, {
  // 				breakpoint: 480,
  // 				settings: {
  // 					slidesToShow: 1,
  // 					slidesToScroll: 1,
  // 					infinite: true,
  // 				}
  // 			}, {
  // 				breakpoint: 320,
  // 				settings: {
  // 					slidesToShow: 1,
  // 					slidesToScroll: 1,
  // 					infinite: true,
  // 				}
  // 			}]
  // 		})

  // 		let slideIndex = $('.slick-slide:not(.slick-cloned)').length
  // 		$('.add-slide').on('click', () => {
  // 			slideIndex++
  // 			$('.slick-slider').slick('slickAdd', '<li><h3>' + slideIndex + '</h3></li>')
  // 			$('.slick-slider').slick('slickGoTo', 'slickCurrentSlide' + 1)
  // 		})

  // 		$('.remove-slide').on('click', () => {
  // 			$('.slick-slider').slick('slickRemove', slideIndex - 1)
  // 			if (slideIndex !== 0) {
  // 				slideIndex--
  // 			}
  // 		});
  // 	}, 100);
  // }

  getGeneralAnalysis() {
    const pvyProgressValue = this.searchResp?.statistics?.probabilityOfValidity;
    const pvyProgressVal =
      100 - this.searchResp?.statistics?.probabilityOfValidity;
    const debunkprogress = pvyProgressVal.toFixed(1).replace(/\.0$/, '');
    let general_analysis_text = '';
    if (pvyProgressValue <= 50) {
      general_analysis_text = `There is a ${debunkprogress}% chance that the statement is a personal opinion but not a fact.`;
    } else {
      general_analysis_text = `Your assertion has been analyzed by Legit TM and found to have a ${pvyProgressValue}% degree of validity.`;
    }

    return general_analysis_text;
  }

  setSearchResponseData() {
    this.emptyVariables();
    this.general_text = this.getGeneralAnalysis();
    this.answerBox = (
      this.searchResp?.searchBoxResults || 'No answer found for this assertion'
    ).replace(/\n/g, '<br/>');
    // this.answerBox = this.sanitizer.bypassSecurityTrustHtml(this.answerBox);
    this.pvgprogress = this.searchResp?.statistics?.probabilityOfValidity || 0;
    this.newsAgencies = this.searchResp?.newsAgencies || [];

    this.http.get<any[]>('./assets/js/favicons.json').subscribe(
      (favicons: any[]) => {
        this.favnewsicons = favicons;

        this.newsAgencies = this.newsAgencies.map((agency) => {
          const matchingFavicon = this.favnewsicons.find(
            (favicon) => favicon.label === agency.name
          );
          agency.bias_score = agency.newsAgency.adfontesmediaRank.bias
            ? agency.newsAgency.adfontesmediaRank.bias
            : 0;
          return {
            ...agency,
            iconUrl: matchingFavicon ? matchingFavicon.icon : null,
          };
        });
      },
      (error) => {
        console.error('Error fetching favicons:', error);
      }
    );

    if (
      this.searchResp?.search_results &&
      this.searchResp?.search_results.length > 0
    ) {
      for (let result of this.searchResp?.search_results) {
        if (result.isdebunking == false) {
          if (result.title == 'Google Fact Check') {
            this.factCheckUrls.citation = !result.has_citation;
            this.factCheckUrls.total_count += parseInt(result.total_count);
            this.factCheckUrls.results.push(...result.results);
          }
          if (result.title == 'Wiki') {
            this.wikiUrls.citation = !result.has_citation;
            this.wikiUrls.total_count += parseInt(result.total_count);
            this.wikiUrls.result_count = result.result_count;
            this.wikiUrls.results.push(...result.results);
          }
          this.supportingUrls.citation = !result.has_citation;
          this.supportingUrls.total_count += parseInt(result.total_count);
          // this.supportingUrls.results.push(...result.results);
        }
        if (result.isdebunking == true) {
          if (result.title == 'Google Fact Check') {
            this.factCheckUrls.citation = !result.has_citation;
            this.factCheckUrls.total_count += parseInt(result.total_count);
            this.factCheckUrls.results.push(...result.results);
          }
          if (result.title == 'Wiki') {
            this.wikiUrls.citation = !result.has_citation;
            this.wikiUrls.total_count += parseInt(result.total_count);
            this.wikiUrls.result_count = result.result_count;
            this.wikiUrls.results.push(...result.results);
          }
          this.debunkingUrls.citation = !result.has_citation;
          this.debunkingUrls.total_count += parseInt(result.total_count);
          // this.debunkingUrls.results.push(...result.results);
        }
      }
    }

    this.supportingUrls.results = this.searchResp?.supportingTop5 || [];
    this.debunkingUrls.results = this.searchResp?.debunkingTop5 || [];
    this.supportingUrls.final_count = this.intToString(
      this.supportingUrls.total_count
    );
    this.debunkingUrls.final_count = this.intToString(
      this.debunkingUrls.total_count
    );
    this.factCheckUrls.total_count = this.intToString(
      this.factCheckUrls.total_count
    );
    this.wikiUrls.total_count = this.intToString(this.wikiUrls.total_count);

    this.sortBasedOnRank(this.factCheckUrls.results);
    this.sortBasedOnRank(this.wikiUrls.results);

    switch (this.searchResp?.resultHanddler) {
      case 0:
        this.labelText = 'Unknown';
        break;
      case 1:
        this.labelText = 'GoogleChrome';
        break;
      case 2:
        this.labelText = 'SerpApi';
        break;
      case 3:
        this.labelText = 'ChatGpt';
        break;
      default:
        this.labelText = 'Unknown';
    }

    if (localStorage.getItem('demo_data') != 'true') {
      this.showLabels();
    }

    setTimeout(() => {
      if (this.newsAgencies && this.newsAgencies.length > 0) {
        this.setBiasChart();
      }
    }, 2500);
  }

  setBiasChart(): void {
    for (let i = 0; i < this.newsAgencies.length; i++) {
      let data = this.newsAgencies[i]?.newsAgency?.adfontesmediaRank?.bias
        ? [Number(this.newsAgencies[i]?.newsAgency?.adfontesmediaRank?.bias)]
        : [0];
      let id = 'linear-guage-div' + i;

      this.Highcharts.chart(
        id,
        {
          chart: {
            inverted: true,
            height: 65,
            events: {
              load: function () {
                this.series[0].update({
                  type: 'line',
                  data: data,
                });
              },
            },
          },
          tooltip: { enabled: false },
          credits: {
            enabled: false,
          },
          title: {
            text: null,
          },
          xAxis: {
            lineColor: '#C0C0C0',
            labels: {
              enabled: false,
            },
            tickLength: 0,
          },
          yAxis: {
            min: -40,
            max: 40,
            tickLength: 5,
            tickWidth: 0,
            tickColor: '#C0C0C0',
            gridLineColor: '#C0C0C0',
            gridLineWidth: 1,
            minorTickInterval: 5,
            minorTickWidth: 1,
            minorTickLength: 5,
            minorGridLineWidth: 0,

            title: null,
            labels: {
              format: '{value}',
            },
            plotBands: [
              {
                from: -40,
                to: -30,
                color: '#0000FF',
              },
              {
                from: -30,
                to: -18,
                color: '#0033FF',
              },
              {
                from: -18,
                to: -12,
                color: '#0066FF',
              },
              {
                from: -12,
                to: -6,
                color: '#0099FF',
              },
              {
                from: -6,
                to: 6,
                color: '#66CCFF',
              },
              {
                from: 6,
                to: 12,
                color: '#FF9900',
              },
              {
                from: 12,
                to: 18,
                color: '#FF6600',
              },
              {
                from: 18,
                to: 30,
                color: '#FF3300',
              },
              {
                from: 30,
                to: 40,
                color: '#FF0000',
              },
            ],
          },
          legend: {
            enabled: false,
          },

          series: [
            {
              type: 'line',
              data: [0],
              color: '#000000',
              dataLabels: {
                enabled: true,
                align: 'center',
                format: '{point.y}',
              },
            },
          ],
        },
        function (chart) {
          // console.log(chart);
        }
      );
    }
  }

  intToString(value: number): string {
    const suffixes: string[] = ['', 'K', 'M', 'B', 'T'];
    const suffixNum: number = Math.floor(('' + value).length / 3);
    let shortValue: number | string = parseFloat(
      (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1);
    }
    return `${shortValue} ${suffixes[suffixNum]}`;
  }

  sortBasedOnRank(resultLinks: any): any {
    return resultLinks.sort((a, b) => b.rank - a.rank);
  }

  emptyVariables() {
    this.answerBox = '';
    this.pvgprogress = 0;
    this.newsAgencies = [];
    this.selectedCheckBoxes = new Set([]);
    this.supportingUrls = {
      results: [],
      total_count: 0,
      final_count: '',
    };
    this.debunkingUrls = {
      results: [],
      total_count: 0,
      final_count: '',
    };
    this.factCheckUrls = {
      results: [],
      total_count: 0,
    };
    this.wikiUrls = {
      results: [],
      total_count: 0,
      result_count: '0.0',
    };
  }

  viewMore(url: string) {
    window.open(url, '_blank');
  }

  onCheckboxChange(event: any, result: any) {
    if (event.checked) {
      this.selectedCheckBoxes.add(result);
    } else {
      this.selectedCheckBoxes.delete(result);
    }
  }

  onWriterChange(ob) {
    this.selected = ob.value;
  }

  createCitation() {
    let citiationIds = Array.from(this.selectedCheckBoxes) || [];
    if (citiationIds == null || citiationIds.length == 0) {
      this.openToaster(
        false,
        true,
        'Please select few citations from the article.'
      );
      return;
    }

    if (!this.selected) {
      this.openToaster(false, true, 'Please select a citation format.');
      return;
    }
    PageTransitionEvent;

    let request = {
      citation_format: this.selected,
      citation_id: citiationIds,
    };
    // console.log(request);
    sessionStorage.setItem('create_citation', JSON.stringify(request));

    setTimeout(() => {
      this.router.navigate(['/citations']);
    }, 100);
  }

  loadMore() {
    this.moreCont = !this.moreCont;
  }

  copyAnswerText() {
    let copyText = `${this.answerBox}`.replace(
      /<\/?br\s*\/?>(?!<br\s*\/?>)/g,
      '\n'
    );
    copyText = copyText.replace(/<\/?br\s*\/?>/g, '\n');
    const general_analysis_text = this.getGeneralAnalysis();
    const copiedText = copyText + '\n\n' + general_analysis_text;
    navigator.clipboard.writeText(copiedText);
    this.openToaster(true, false, 'Copied to your clipboard');
  }

  openToaster(isSuccess: boolean, isError: boolean, message: string) {
    if (isSuccess) {
      this.showSuccessToaster = true;
      this.showSuccessMsg = message;
    } else if (isError) {
      this.showErrorToaster = true;
      this.showErrorMsg = message;
    }

    setTimeout(() => {
      this.closeToaster();
    }, 10000);
  }

  closeToaster() {
    this.showErrorToaster = false;
    this.showErrorMsg = '';
    this.showSuccessToaster = false;
    this.showSuccessMsg = '';
  }
}
