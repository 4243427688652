<!-- Mat loader starts -->
<div class="page_loader" *ngIf="isLoading">
  <!-- <mat-spinner></mat-spinner> -->
  <div class="text-loader">
    <img src="../../assets/images/svg/logo-icon.svg" alt="logo icon" />
    <div class="word"></div>
  </div>
</div>
<!-- Mat loader starts -->

<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
  <div class="outer-container">
    <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster" />
  </div>
  <div class="inner-container">
    <h3>Error</h3>
    <p>{{ showErrorMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
  <div class="outer-container">
    <img
      src="../../assets/images/svg/success-toaster-icon.svg"
      alt="warning toaster"
    />
  </div>
  <div class="inner-container">
    <h3>Success</h3>
    <p>{{ showSuccessMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<!-- warning toast ends -->

<!-- Welcome Message -->
<div class="global-modal-popup" *ngIf="showWelcomePopup" #modalView>
  <div class="modal-pop-header">
    <h3>Welcome Message</h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <p>
      Welcome to our ever-evolving system, designed to continuously improve. By
      harnessing the power of learning knowledge reservoirs, your results will
      enhance with each input. Currently, we request singular, focused
      assertions. Future updates will accommodate more complex inputs.<br /><br />
      Your outcomes will swiftly refine with increased usage. Should you
      encounter any questionable results, please reach out to us at
      support@tqllc.company to share your findings. The TQLLC Team sincerely
      appreciates your use of our Legit<sup>TM</sup> tool and hopes you
      find it enjoyable!
    </p>
  </div>
</div>

<!-- Part of Speech -->
<div class="global-modal-popup" *ngIf="showPartsPopup" #modalView>
  <div class="modal-pop-header">
    <h3>Parts of Speech</h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="speech-part">
      <!-- <div class="icons-container">
      <img class ="zoom-icon" (click)="zoomIn()" src ="../../assets/images/svg/zoom_in.svg">
      <img class ="zoom-icon" (click)="zoomOut()" src ="../../assets/images/svg/zoom_out.svg">
    </div> -->
      <div
        class="scroll-container"
        *ngIf="sentVisualizationHTML != ''"
        [innerHTML]="sentVisualizationHTML"
        (load)="applySVGStyles()"
        [style.transform]="'scale(' + zoomLevel + ')'"
      ></div>
      <p *ngIf="sentVisualizationHTML == ''">
        No Visualization Found for this sentence
      </p>
    </div>
  </div>
</div>

<!-- General Definitions -->
<div class="global-modal-popup general-def" *ngIf="showGeneralPopup" #modalView>
  <div class="modal-pop-header">
    <h3>
      General Definitions
      <div class="tooltip-info">
        <a class="info-icon"></a>
        <div class="info-tooltip">
          <p>
            Explore the definitions of words and phrases from the assertion.
            This section helps users understand the meaning of specific terms
            used in the context of their assertion.
          </p>
        </div>
      </div>
    </h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="general-define-sec">
      <ul *ngIf="statKeywordsArray.length > 0">
        <li *ngFor="let keyObj of statKeywordsArray">
          <em>{{ keyObj.keyword }}:</em> {{ keyObj.definition[0] }};
        </li>
      </ul>
      <p *ngIf="statKeywordsArray.length == 0">
        Looking for Definitions for this sentence..
      </p>
    </div>
  </div>
</div>

<!-- General Analysis -->
<div
  class="global-modal-popup general-anls"
  *ngIf="showGeneralAnalysisPopup"
  #modalView
>
  <div class="modal-pop-header">
    <h3>
      General Analysis
      <div class="tooltip-info">
        <a class="info-icon"></a>
        <div class="info-tooltip">
          <p>
            The General Analysis section provides a basic assessment of the
            assertion. It aims to determine the probability of the assertion
            being a factual statement.
          </p>
        </div>
      </div>
    </h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="general-define-sec">
      <ul *ngIf="pvyProgressValue <= 50">
        <li>
          There is a {{ debunkprogress }}% chance that the statement is a
          personal opinion but not a fact.
        </li>
        <li>Please review the links below.</li>
      </ul>
      <ul *ngIf="pvyProgressValue > 50">
        <li>
          Your assertion has been analyzed by Authentificaiton and found to have
          a {{ pvyProgressValue }}% degree of validity.
        </li>
        <!-- <li>Please review the links below.</li> -->
      </ul>
    </div>
  </div>
</div>

<!-- Entities -->
<div class="global-modal-popup entities" *ngIf="showEntitiesPopup" #modalView>
  <div class="modal-pop-header">
    <h3>
      Entities
      <div class="tooltip-info">
        <a class="info-icon"></a>
        <div class="info-tooltip">
          <p>
            In this section, we extract and display the key entities from the
            assertion. These are the important terms and concepts related to the
            topic of the assertion.
          </p>
        </div>
      </div>
    </h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="general-define-sec">
      <ul *ngIf="entities.length > 0">
        <li *ngFor="let keyObj of entities">
          <b>{{ keyObj.text }}</b> ({{ keyObj.label }})<a
            href="{{ keyObj.url }}"
            target="_blank"
            >({{ keyObj.url }})</a
          >
        </li>
      </ul>
      <p *ngIf="entities.length == 0">No Entities Found</p>
    </div>
  </div>
</div>

<!-- Charecter Counter -->
<div
  class="character-global-modal-popup cc-count"
  *ngIf="showCharacterCounterPopup"
  #modalView
>
  <div class="modal-pop-header">
    <!-- <h3>Character counter</h3> -->
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="charecter-counter-sec">
      <ul>
        <li>
          <div class="cc-title">Characters</div>
          <div class="cc-count">
            {{ this.searchStatistics.character_counter }}
          </div>
        </li>
        <li>
          <div class="cc-title">Words</div>
          <div class="cc-count">{{ this.searchStatistics.numberOfWords }}</div>
        </li>
        <li>
          <div class="cc-title">Sentences</div>
          <div class="cc-count">
            {{ this.searchStatistics.numberOfSentences }}
          </div>
        </li>
        <li>
          <div class="cc-title">Uncivil Words and Phrases</div>
          <div class="cc-count">0</div>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Leave your Feedback -->
<div
  class="global-modal-popup feedback-popup"
  *ngIf="showFeedbackPopup"
  #modalView
>
  <div class="modal-pop-header">
    <h3>Leave your feedback</h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="feedback-sec">
      <div class="inner-feedback-block">
        <div class="feedback-ques">
          <!-- <span>2</span> -->
          <h3>
            How satisfied are you with the Legit<sup>TM</sup> tool response?
          </h3>
          <div class="star-container" *ngIf="selectedRating">
            <ng-container *ngFor="let star of totalStars; let i = index">
              <span
                class="star"
                [class.yellow]="i < selectedRating"
                [class.grey]="i >= selectedRating"
                (click)="selectedRating = i + 1"
              >
                &#9733;
              </span>
            </ng-container>
          </div>
        </div>
        <div class="feedback-options clearfix">
          <ul>
            <mat-radio-group>
              <li>
                <mat-radio-button
                  value="5"
                  (change)="
                    onChange($event, 'very_satisfied', 'feedback_rating')
                  "
                ></mat-radio-button
                >Very Satisfied
              </li>
              <li>
                <mat-radio-button
                  value="4"
                  (change)="onChange($event, 'satisfied', 'feedback_rating')"
                ></mat-radio-button
                >Satisfied
              </li>
              <li>
                <mat-radio-button
                  value="3"
                  (change)="onChange($event, 'neutral', 'feedback_rating')"
                ></mat-radio-button
                >Neutral
              </li>
              <li>
                <mat-radio-button
                  value="2"
                  (change)="onChange($event, 'unsatisfied', 'feedback_rating')"
                ></mat-radio-button
                >Unsatisfied
              </li>
              <li>
                <mat-radio-button
                  value="1"
                  (change)="
                    onChange($event, 'very_unsatisfied', 'feedback_rating')
                  "
                ></mat-radio-button
                >Very Unsatisfied
              </li>
            </mat-radio-group>
          </ul>
        </div>
      </div>

      <div class="inner-feedback-block">
        <div class="feedback-ques">
          <!-- <span>3</span> -->
          <h3>
            Have you found the Truth Meter score provided by the software to be
            helpful?
          </h3>
        </div>
        <div class="feedback-options clearfix">
          <ul>
            <mat-radio-group>
              <li>
                <mat-radio-button
                  value="1"
                  (change)="onChange($event, 'Yes', 'feedback_meter')"
                ></mat-radio-button
                >Yes
              </li>
              <li>
                <mat-radio-button
                  value="2"
                  (change)="onChange($event, 'No', 'feedback_meter')"
                ></mat-radio-button
                >No
              </li>
            </mat-radio-group>
          </ul>
        </div>
      </div>

      <div class="inner-feedback-block">
        <div class="feedback-ques">
          <!-- <span>3</span> -->
          <h3>How often do you use the fact-checking software?</h3>
        </div>
        <div class="feedback-options clearfix">
          <ul>
            <mat-radio-group>
              <li>
                <mat-radio-button
                  value="1"
                  (change)="onChange($event, 'Daily', 'feedback_frequency')"
                ></mat-radio-button
                >Daily
              </li>
              <li>
                <mat-radio-button
                  value="2"
                  (change)="onChange($event, 'Weekly', 'feedback_frequency')"
                ></mat-radio-button
                >Weekly
              </li>
              <li>
                <mat-radio-button
                  value="2"
                  (change)="onChange($event, 'Monthly', 'feedback_frequency')"
                ></mat-radio-button
                >Monthly
              </li>
              <li>
                <mat-radio-button
                  value="2"
                  (change)="onChange($event, 'Rarely', 'feedback_frequency')"
                ></mat-radio-button
                >Rarely
              </li>
            </mat-radio-group>
          </ul>
        </div>
      </div>

      <div class="inner-feedback-block">
        <div class="feedback-ques">
          <!-- <span>3</span> -->
          <h3>
            Would you recommend the fact-checking software considering the
            features and services it offers?
          </h3>
        </div>
        <div class="feedback-options clearfix">
          <ul>
            <mat-radio-group>
              <li>
                <mat-radio-button
                  value="1"
                  (change)="onChange($event, 'Yes', 'feedback_price')"
                ></mat-radio-button
                >Yes
              </li>
              <li>
                <mat-radio-button
                  value="2"
                  (change)="onChange($event, 'No', 'feedback_price')"
                ></mat-radio-button
                >No
              </li>
            </mat-radio-group>
          </ul>
        </div>
      </div>

      <div class="inner-feedback-block">
        <div class="feedback-ques">
          <!-- <span>1</span> -->
          <h3>What is Your Conclusion?</h3>
        </div>
        <div class="feedback-options">
          <p>
            After reading the given articles from your analysis, do you consider
            the assertion to be valid?
          </p>
          <textarea
            placeholder="Enter your conclusion here..."
            value="{{ feedbackConclusion }}"
            (input)="onChange($event, '', 'feedback_conclusion')"
            autocomplete="off"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="submit-btn" (click)="saveFeedback()">Submit</button>
    </div>
  </div>
</div>

<!-- Answer modal popup -->
<div
  class="global-modal-popup answer-popup"
  *ngIf="showAnswerBoxPopup"
  #modalView
>
  <div class="modal-pop-header">
    <h3>Answer</h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="answer_content-block" [innerHTML]="answerBox"></div>
  </div>
</div>

<div
  class="global-modal-popup report_bug"
  *ngIf="showReportBoxPopup"
  #modalView
>
  <div class="modal-pop-header">
    <h3>Report a Bug</h3>
    <p>Help us grow by reporting a bug</p>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="report-options">
      <textarea
        placeholder="Enter your conclusion here..."
        value="{{ report_conclusion }}"
        (input)="onChange($event, '', 'report_conclusion')"
        autocomplete="off"
      ></textarea>
    </div>
    <div class="modal-footer report">
      <button class="submit-btn" (click)="savereportbug()">Submit</button>
    </div>
  </div>
</div>
<!-- Assertion Popup -->
<div
  class="global-modal-popup answer-popup"
  *ngIf="showAssertionPopup"
  #modalView
>
  <div class="modal-pop-header">
    <h3>Assertion</h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="answer_content-block">
      <p>
        It is true that the future is unwritten and unable to be perfectly
        predicted. That does not mean stop word here that prediction strategies
        by analyzing the past, especially when there is accurate data to aid in
        future predictions, can not lead to close estimates that have the
        possibility to yield large investor profits. This empirical research
        paper attempts to predict growth in future initial public offerings
        (IPOs) by analyzing factors effecting IPOs in 2012. To start this
        research let us first understand what an IPO is and how they come to be.
        Before than can be an IPO, there must be a private company. Private
        corporations hold there ownership to the actual owners of the
        organization; opposite to private companies, publicly traded companies
        are owned by the public. Some question here? The transition from private
        to public is one of the most impact ventures that the owners partake in.
        There are many reasons that companys go public, the main one is a fast
        and effective way of raising a potentially large amount of capital to
        pay off debts or expand
      </p>
    </div>
  </div>
</div>
<!-- Syllogims Popup -->
<div
  class="global-modal-popup answer-popup"
  *ngIf="showSyllogimsPopup"
  #modalView
>
  <div class="modal-pop-header">
    <h3>Syllogims</h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="answer_content-block">
      <ul *ngIf="sentenceData.length > 0">
        <li *ngFor="let sentence of sentenceData">{{ sentence }}</li>
        <li *ngIf="syllogismConclusion.length != 0">
          <b>Conclusion:</b> {{ syllogismConclusion }}
        </li>
      </ul>
      <p *ngIf="sentenceData.length == 0">No Syllogisms Found</p>
    </div>
  </div>
</div>
<!-- Fallacies Popup -->
<div
  class="global-modal-popup answer-popup"
  *ngIf="showFallaciesPopup"
  #modalView
>
  <div class="modal-pop-header">
    <h3>Logical Fallacies</h3>
    <div class="close-popup">
      <a class="close-icon" (click)="closeModal()"></a>
    </div>
  </div>
  <div class="modal-container">
    <div class="answer_content-block">
      <ul *ngIf="fallaciesData.length > 0">
        <li *ngFor="let fallacy of fallaciesData">
          {{ fallacy.assertion }}

          <span style="white-space: nowrap">
            <span style="color: #d0bb00; font-weight: 600">
              (Fallacy Name: {{ fallacy.fallacy_name }})
            </span>
            <span style="color: #008400; font-weight: 600">
              (Score: {{ fallacy.fallacy_score | number : "1.2-2" }})
            </span>
          </span>
        </li>
      </ul>
      <p *ngIf="fallaciesData.length == 0">No logical fallacies found</p>
    </div>
  </div>
</div>
