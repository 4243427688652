<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
    <div class="outer-container">
      <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster" />
    </div>
    <div class="inner-container">
      <h3>Error</h3>
      <p>{{ showErrorMsg }}</p>
    </div>
    <a (click)="closeToaster()"
      ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
    /></a>
  </div>
  <div class="toast success static-toaster" *ngIf="showSuccessToaster">
    <div class="outer-container">
      <img
        src="../../assets/images/svg/success-toaster-icon.svg"
        alt="warning toaster"
      />
    </div>
    <div class="inner-container">
      <h3>Success</h3>
      <p>{{ showSuccessMsg }}</p>
    </div>
    <a (click)="closeToaster()"
      ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
    /></a>
  </div>
  <!-- warning toast ends -->
  
  <div class="search_results_view_section">
    <div class="search-results-section">
      <div class="user-upld-document">
        <a href="javascript:void(0);" routerLink="/"
          ><img src="../../assets/images/svg/back-arrow.svg" alt="back arrow"
        /></a>
        <p>
          Uploaded document: <span>{{ documentData.fileName }}</span>
        </p>
      </div>
      <div class="results-category">
        <ul>
          <li>
            <a href="javascript:void(0);" class="true"
              >True:
              <span>{{ documentData.trueAssertionsCount }}</span> assertions</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" class="partially-true"
              >Partially True:
              <span>{{ documentData.partiallyTrueAssertionsCount }}</span>
              assertions</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" class="false"
              >False:
              <span>{{ documentData.falseAssertionsCount }}</span> assertions</a
            >
          </li>
        </ul>
        <div class="correction-btn">
          <a href="javascript:void(0);"
            >Corrections: <span>{{ corrections }}</span></a
          >
        </div>
      </div>
      <div class="assertions_data" (clickOutside)="closeCorrection()">
        <div class="assertion-content-sec" [innerHTML]="htmlContent" (click)="addClickListenersToGuidElements()"></div>
        <!-- <h3>Introduction</h3> -->
        <!-- <div class="assertion-content-sec" *ngFor="let sentence of sentenceData"> -->
          <!-- <span class="count">15</span> -->
          <!-- <p> -->
            <!-- <span
              [ngClass]="getClassForTruthValue(sentence.truthValue)"
              class="assertion-text"
              (click)="redirectToAssertion(sentence.guid)"
              >{{ sentence.sentence }}
              <div class="edit-actions">
                <ul>
                  <li>
                    <a href="javascript:void(0);">Rerun all edited assertions</a>
                  </li>
                  <li><a href="javascript:void(0);">Rerun this assertion</a></li>
                </ul>
              </div>
            </span> -->
            <!-- <span class="false assertion-text" (click)="redirectToAssertion()"> Nobody knows if a stock is gonna go up, down, sideways or in [explitive deleted] circles. Least of all, stockbrokers
                  <div class="edit-actions">
                      <ul>
                          <li><a href="javascript:void(0);">Rerun all edited assertions</a></li>
                          <li><a href="javascript:void(0);">Rerun this assertion</a></li>
                      </ul>
                  </div>
                  </span><span class="editor">(Martin   , 2013)</span> -->
          <!-- </p>
        </div> -->
        <!-- <div class="assertion-content-sec">
                  <span class="count">12</span>
                  <p><span class="true assertion-text" (click)="redirectToAssertion()">It is true that the future is unwritten and unable to be perfectly predicted. That does not mean <span class="correction" (click)="showCorrection()" [ngClass]="{'showcorrection-tooltip' : showcorrectionTooltip}">stop word here
                      <div class="edit-actions">
                          <ul>
                              <li><a href="javascript:void(0);">Rerun all edited assertions stop</a></li>
                              <li><a href="javascript:void(0);">Rerun this assertion</a></li>
                              <li><a href="javascript:void(0);">Edit this assertion</a></li>
                          </ul>
                      </div>
                  </span> that prediction strategies by analyzing the past, especially when there is accurate data to aid in future predictions,  can not lead to close estimates that have the possibility to yield large investor profits. This empirical research  paper attempts to predict growth in future initial public offerings (IPOs) by analyzing factors effecting IPOs  in 2012. To start this research let us first understand what an IPO is and how they come to be. Before  than can be an IPO, there must be a private company. Private corporations hold there ownership to the  actual owners of the organization; opposite to private companies, publicly traded companies are owned by  the public. <span class="correction" (click)="showCorrection()" [ngClass]="{'showcorrection-tooltip' : showcorrectionTooltip}">Some question here?
                      <div class="edit-actions">
                          <ul>
                              <li><a href="javascript:void(0);">Rerun all edited assertions stop</a></li>
                              <li><a href="javascript:void(0);">Rerun this assertion</a></li>
                          </ul>
                      </div>
                  </span> The transition from private to public is one of the most impact ventures that the owners partake  in. There are many reasons that companys go public, the main one is a fast and effective way of raising  a potentially large amount of capital to pay off debts or expand.
                      <div class="edit-actions">
                          <ul>
                              <li><a href="javascript:void(0);">Rerun all edited assertions</a></li>
                              <li><a href="javascript:void(0);">Rerun this assertion</a></li>
                          </ul>
                      </div>
                  </span>
                  <span class="false assertion-text" (click)="redirectToAssertion()">The actual process that a company goes  though in order to be a be publicly traded company is known as the initial public offering process.
                      <div class="edit-actions">
                          <ul>
                              <li><a href="javascript:void(0);">Rerun all edited assertions</a></li>
                              <li><a href="javascript:void(0);">Rerun this assertion</a></li>
                          </ul>
                      </div>
                  </span>
                  <span class="partially assertion-text" (click)="redirectToAssertion()">Either private companies go to an investment bank, or any firm that can act as an underwriter, or the firm able to  act as an underwriter goes to the private company to begin negotiations.
                      <div class="edit-actions">
                          <ul>
                              <li><a href="javascript:void(0);">Rerun all edited assertions</a></li>
                              <li><a href="javascript:void(0);">Rerun this assertion</a></li>
                          </ul>
                      </div>
                  </span></p>
              </div>
              <div class="assertion-content-sec">
                  <span class="count">15</span>
                  <p><span class="partially assertion-text" (click)="redirectToAssertion()">The main topics for discussion that  some text and the document is too lengthy from here.
                      <div class="edit-actions">
                          <ul>
                              <li><a href="javascript:void(0);">Rerun all edited assertions</a></li>
                              <li><a href="javascript:void(0);">Rerun this assertion</a></li>
                          </ul>
                      </div>
                  </span></p>
              </div> -->
      </div>

      <div class="assertions-editor-section">
          <!-- <app-ngx-editor [placeholder]="'Enter text here...'" [spellcheck]="true" [(ngModel)]="edtablehtmlContent"></app-ngx-editor> -->
      </div>
    </div>
  
    <div class="results-score-section">
     <div class="download-assertion" (click)="downloadDocument()"><img src="../../assets/images/svg/download-icon.svg" alt="download"></div>
     <!---- Overall Score ---->
     <div class="overall_scroll_sec">
        <div class="truth-meter-section">
          <h3>Overall Score</h3>
          <!-- <span  class="label_text" *ngIf="labelText && !ranking && env === 'dev-portal'">{{labelText}}</span> -->
          <ng-container>
            <div
              class="trust-guage-section"
              [attr.data-truth-meter-score]="truthMeter"
            >
              <rg-gauge-chart
                [canvasWidth]="canvasWidth"
                [needleValue]="needleValue"
                [centralLabel]="centralLabel"
                [options]="options"
              >
              </rg-gauge-chart>
              <h4>{{ graphType }}</h4>
            </div>
          </ng-container>
        </div>
      </div>
  
      <!---- Counter ---->
      <div class="counter-section">
        <h3>Counter</h3>
        <div class="counter-block">
            <ul>
                <li *ngIf="documentData.totalSentences">Total Sentences: {{documentData.totalSentences ? documentData.totalSentences : 0}}</li>
                <li *ngIf="documentData.characterCounter">Characters Count: {{documentData.characterCounter ? documentData.characterCounter : 0}}</li>
                <li *ngIf="documentData.assertionsWithErrorsCount">Assertions with Errors: {{documentData.assertionsWithErrorsCount ? documentData.assertionsWithErrorsCount : 0}}</li>
                <li *ngIf="documentData.logicalFallaciesCount">Logical Fallacies Count: {{documentData.logicalFallaciesCount ? documentData.logicalFallaciesCount : 0}}</li>
            </ul>
        </div>
      </div>
  
      <!---- View Assertions ---->
      <div class="feature-links">
        <ul>
          <!-- <li><a (click)="openModal('assertion')">View Assertions</a></li> -->
          <li><a (click)="openModal('Syllogims')">View Syllogisms</a></li>
          <li><a (click)="openModal('Fallacies')">View Logical Fallacies</a></li>
        </ul>
      </div>
    </div>
  </div>