import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { environment } from '../../environments/environment';
import { ModalPopupService } from '../services/modalPopup.services';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalmodalpopupComponent } from '../globalmodalpopup/globalmodalpopup.component';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';

//probability of validity graph
const stbar1 = 0;
const stbar2 = 0;

//change of opinion graph
const truprogress = 36;
const ptvprogress = 86;
const flsprogress = 26;

declare var $: any;

@Component({
  selector: 'app-search-results-view',
  templateUrl: './search-results-view.component.html',
  styleUrls: ['./search-results-view.component.scss'],
})
export class SearchResultsViewComponent implements OnInit {
  // Assertions text editor
  // html$ = '';
  // get html(): string {
  //   return this.html$;
  // }

  // set html(html: string) {
  //   this.html$ = html;
  //   this.htmlContent = html.replace(/<p><\/p>/ig, '<p><br><\/p>');
  // }

  // Loader & Error Handling Variables
  isLoading: boolean = false;
  showSuccessToaster: boolean = false;
  showSuccessMsg: string = '';
  showErrorToaster: boolean = false;
  showErrorMsg: string = '';
  @Input() truthMeter = 0;
  @Input() labelVal = 0;
  @Input() ranking: boolean;

  // Guage chart
  public canvasWidth;
  public needleValue;
  public counter;
  public centralLabel;
  public label;
  public options;
  public showcorrectionTooltip: boolean = false;

  // statics
  pvgprogress = 0;
  pvyprogress: any = 0;

  //chance of opinion
  trueprogress = 0;
  ptprogress = 0;
  falseprogress = 0;
  dialogRef: MatDialogRef<any>;

  graphType: string = 'TRUE';
  labelText: string = '';
  env: string = environment.env;
  htmlContent: SafeHtml;
  edtablehtmlContent: string;
  docId: string;
  documentData: any = {};
  sentenceData: any = {};
  corrections: number = 0;
  allAssertions: any = [];
  syllogims: any = [];
  private eventListeners: (() => void)[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    private modalPopupService: ModalPopupService,
    private dialog: MatDialog,
    private el: ElementRef,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer, // Import DomSanitizer
    private http: HttpClient
  ) {
    this.docId = '';
    this.docId = this.route.snapshot.queryParamMap.get('assertion')
      ? this.route.snapshot.queryParamMap.get('assertion')
      : null;
    // console.log(this.docId);
    localStorage.setItem('docId', this.docId);

    this.readDocumentById(this.docId);
    this.getSentenceById(this.docId);

    var style = document.createElement('style');
    // Add CSS rules to the style element
    style.innerHTML = `
        .MsoNormal{
            display: inline-block; /* Ensure the transform works properly */
            transition: transform 0.3s ease; /* Smooth transition effect */
            cursor: pointer;
            font-family: "Montserrat-regular", Arial, Helvetica, sans-serif !important;
        }

        .MsoNormal:hover{
            transform: scale(1.02); /* Zoom in by 10% */
        }
      /* Add more styles here */
    `;

    // Append the style element to the <head> section
    document.head.appendChild(style);

    //  Assertions value with inner value
    // this.htmlContent = this.getInnerHTMLValue();
    this.sanitizer.bypassSecurityTrustHtml('this.htmlContent');
  }

  // getInnerHTMLValue() {
  //   this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
  // }

  //   Below is the hardcoded value for sampleHtmlContent. Note that you need to get it dynamically using api. I wrote comment already where you can get it.
  sampleHtmlContent = `<html>
        <head>
        <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
        <meta content="Microsoft Word 15 (filtered)" name="Generator"/>
        <style>
        <!--
        /* Font Definitions */
        @font-face
          {font-family:"Cambria Math";
          panose-1:2 4 5 3 5 4 6 3 2 4;}
        /* Style Definitions */
        p.MsoNormal, li.MsoNormal, div.MsoNormal
          {margin:0cm;
          line-height:115%;
          font-size:11.0pt;
          font-family:"Arial",sans-serif;}
        .MsoPapDefault
          {line-height:115%;}
        @page WordSection1
          {size:595.45pt 841.7pt;
          margin:72.0pt 72.0pt 72.0pt 72.0pt;}
        div.WordSection1
          {page:WordSection1;}
        -->
        </style>
        </head>
        <body lang="EN-US" style="word-wrap:break-word">
        </body>
        </html>
        `;

  ngOnInit(): void {
    // this.oribabilityYellowGraph();
    // this.probabilityGreenGraph();
    // this.trueOpinonGraph();
    // this.ptOpinonGraph();
    // this.falseOpinonGraph();

    // Guage Chart
    this.canvasWidth = 230;
    this.needleValue = '0';
    this.counter = 0;
    this.centralLabel = '';
    this.options = {
      hasNeedle: true,
      needleColor: '#3B3743',
      needleUpdateSpeed: 3000,
      arcColors: [
        '#FF1919',
        '#FF231B',
        '#FF2C1C',
        '#FF361E',
        '#FF3F1F',
        '#FE4921',
        '#FE5322',
        '#FE5C24',
        '#FE6625',
        '#FE6F27',
        '#FE7928',
        '#FE822A',
        '#FE8C2B',
        '#FD962D',
        '#FD9F2E',
        '#FDA930',
        '#FDB231',
        '#FDBC33',
        '#FDC634',
        '#FDCF36',
        '#FDD937',
        '#FCE239',
        '#FCEC3A',
        '#FCF53C',
        '#FCFF3D',
        '#FCFF3D',
        '#F3FE3C',
        '#E9FD3C',
        '#E0FC3B',
        '#D7FB3A',
        '#CEFA39',
        '#C4F939',
        '#BBF838',
        '#B2F737',
        '#A8F636',
        '#9FF536',
        '#96F435',
        '#8DF334',
        '#83F233',
        '#7AF133',
        '#71F032',
        '#67EF31',
        '#5EEE30',
        '#55ED30',
        '#4BEC2F',
        '#42EB2E',
        '#39EA2D',
        '#30E92D',
        '#26E82C',
        '#1DE72B',
      ],
      arcDelimiters: [
        2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38,
        40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74,
        76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96, 98,
      ],
      arcOverEffect: true,
      // rangeLabel: ['0', '100'],
      needleStartValue: 0,
      arcpadding: 1,
    };

    this.setGuageChart();
    // this.setTruthMeterLabel();

    // Here, need to use sanitizer and bypass security trust html
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(
      this.sampleHtmlContent
    );
    // After setting the content, add click listeners
    setTimeout(() => {
      this.addClickListenersToGuidElements();
    }, 0);
  }

  ngOnChanges(changes: any): void {
    if (
      changes?.truthMeter?.currentValue !==
        changes?.truthMeter?.previousValue &&
      changes?.truthMeter?.previousValue !== undefined
    ) {
      this.setGuageChart();
    }
  }

  addClickListenersToGuidElements() {
    // Select the container element using the ElementRef
    const contentSections = this.el.nativeElement.querySelectorAll(
      '.assertion-content-sec'
    );

    // Iterate through each 'assertion-content-sec' section
    contentSections.forEach((section: HTMLElement) => {
      // Find all elements with a data-guid attribute within this section
      const guidElements = section.querySelectorAll('[data-guid]');

      // Add a click event listener to each element
      guidElements.forEach((element: HTMLElement) => {
        const listener = this.renderer.listen(element, 'click', () => {
          const guid = element.getAttribute('data-guid');
          if (guid) {
            this.redirectToAssertion(guid);
          }
        });

        this.eventListeners.push(listener);
      });
    });
  }

  ngAfterViewInit() {
    // Below code implements bob effect.
    this.addClickListenersToGuidElements();
    setTimeout(() => {
      let step = 1;
      this.options.needleUpdateSpeed = 800;
      const outerInterval = setInterval(() => {
        if (step === 1) {
          this.needleValue = (parseInt(this.needleValue, 10) + 6).toString();
        } else if (step === 2) {
          this.needleValue = (parseInt(this.needleValue, 10) - 6).toString();
        } else if (step === 3) {
          this.needleValue = (parseInt(this.needleValue, 10) - 5).toString();
        } else if (step === 4) {
          this.needleValue = (parseInt(this.needleValue, 10) + 7).toString();
        } else if (step === 5) {
          this.needleValue = (parseInt(this.needleValue, 10) - 4).toString();
        } else if (step === 6) {
          this.needleValue = (parseInt(this.needleValue, 10) + 2).toString();
        }
        step++;
        if (step > 6) {
          step = 1;
        }
      }, 800);
    }, 10000);
  }

  setGuageChart() {
    this.pvgprogress = this.truthMeter;
    const pvyProgressValue = 100 - Number(this.truthMeter);
    this.pvyprogress = pvyProgressValue.toFixed(1).replace(/\.0$/, '');

    this.needleValue = `${this.truthMeter}`;
    if (this.truthMeter >= 0 && this.truthMeter <= 20) {
      this.graphType = 'Very Unlikely';
    } else if (this.truthMeter >= 21 && this.truthMeter <= 40) {
      this.graphType = 'Unlikely';
    } else if (this.truthMeter >= 41 && this.truthMeter <= 60) {
      this.graphType = 'Partially True';
    } else if (this.truthMeter >= 61 && this.truthMeter <= 80) {
      this.graphType = 'Likely';
    } else {
      this.graphType = 'Very Likely';
    }
  }

  setTruthMeterLabel() {
    switch (this.labelVal) {
      case 0:
        this.labelText = 'Google Answer';
        break;
      case 1:
        this.labelText = 'Rank System 1';
        break;
      case 2:
        this.labelText = 'News System Counter';
        break;
      case 3:
        this.labelText = 'Human';
        break;
      case 4:
        this.labelText = 'Google Answer With ChatGPT';
        break;
      case 5:
        this.labelText = 'ChatGPT Answer & Result';
        break;
      default:
        this.labelText = 'Unknown';
    }
  }

  showCorrection() {
    this.showcorrectionTooltip = true;
  }
  closeCorrection() {
    this.showcorrectionTooltip = false;
    console.log('closed');
  }
  toSentenceCase(text: string): string {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  openModal(option: any) {
    if (option == 'Fallacies') {
      console.log('total logical fallacies ::', this.sentenceData);
      sessionStorage.removeItem('fallacies');
      let fallacies = [];
      for (let sentenceObj of this.sentenceData) {
        if (
          sentenceObj &&
          sentenceObj.fallacyDetails &&
          sentenceObj.fallacyDetails?.length > 0
        ) {
          let temp = {
            assertion: sentenceObj.sentence,
            fallacy_score: sentenceObj.fallacyDetails[0].fallacyScore,
            fallacy_name: this.toSentenceCase(
              sentenceObj.fallacyDetails[0].fallacyName
            ),
          };
          fallacies.push(temp);
        }
      }
      sessionStorage.setItem('fallacies', JSON.stringify(fallacies));

      this.modalPopupService.setPopup(option);
      sessionStorage.setItem('option', option);
      setTimeout(() => {
        this.dialogRef = this.dialog.open(GlobalmodalpopupComponent, {
          panelClass: ['global-modal-popup', 'animate__animated', 'zoomIn'],
        });

        this.dialogRef.disableClose = true;
      }, 200);
    }
    if (option == 'Syllogims') {
      sessionStorage.removeItem('syllogims');
      sessionStorage.setItem('syllogims', JSON.stringify(this.syllogims));

      if (this.syllogims.length == 0) {
        this.openToaster(
          true,
          false,
          'Identifying syllogisms... It may take a few seconds.'
        );
      } else {
        this.modalPopupService.setPopup(option);
        sessionStorage.setItem('option', option);
        setTimeout(() => {
          this.dialogRef = this.dialog.open(GlobalmodalpopupComponent, {
            panelClass: ['global-modal-popup', 'animate__animated', 'zoomIn'],
          });

          this.dialogRef.disableClose = true;
        }, 200);
      }
    }
  }

  getSyllogims() {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');

    this.syllogims = [];
    let syllogimsPara = '';
    for (let sentence of this.sentenceData) {
      syllogimsPara += encodeURIComponent(sentence.sentence) + ' ';
    }

    this.userService
      .getsyllogimsData(userId, token, syllogimsPara)
      .subscribe((res: any) => {
        this.syllogims.push(res);
      });
  }

  redirectToAssertion(guid: any) {
    // console.log(this.docId);
    sessionStorage.removeItem('all_assertions');
    console.log(this.allAssertions);
    console.log('guid', guid);
    console.log(this.sentenceData);
    let resultData = this.sentenceData.filter((data: any) => {
      return data.guid == guid;
    });
    if (resultData.length === 0) {
      return;
    } else {
      resultData[0].results.push({ query: resultData[0].sentence });
      console.log(resultData);
    }
    console.log(resultData);
    console.log(resultData[0].results[0].query);

    sessionStorage.setItem(
      'all_assertions',
      JSON.stringify(this.allAssertions)
    );

    if (
      resultData.length > 0 &&
      resultData[0].results &&
      resultData[0].results[0].query
    ) {
      this.router.navigate(['/query'], {
        queryParams: { search: resultData[0].results[0].query },
      });
    }
  }

  downloadDocument() {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    this.userService.downloadDocumentsById(userId, token, this.docId).subscribe(
      (Response: any) => {
        console.log(Response);
        if (Response.data && Response.meta.code == 200) {
          window.open(Response.data, '_blank');
          this.openToaster(true, false, 'File downloaded successfully.');
        } else {
          this.openToaster(
            false,
            true,
            'Failed to retrieve user document File.'
          );
        }
      },
      (error: any) => {
        this.openToaster(false, true, 'Failed to retrieve user document data.');
        console.log(error);
      }
    );
  }

  readDocumentById(docId: string) {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    this.userService.getUserDocumentsById(userId, token, docId).subscribe(
      (Response: any) => {
        if (Response.data && Response.meta.code == 200) {
          this.documentData = Response.data;

          //   Write logic here to get the html file url. It is at this.documentData.htmlFileUrl
          const htmlFileUrl = this.documentData.htmlFileUrl;

          // Load HTML content from the URL
          this.loadHtmlContentFromUrl(htmlFileUrl);
        } else if (Response.meta.code == 1006) {
          console.log('No Document Found');
          this.openToaster(false, true, 'No Document Found');
          this.documentData = {};
        } else {
          this.documentData = {};
          this.openToaster(false, true, 'Failed to fetch document data.');
        }
      },
      (error: any) => {
        this.openToaster(false, true, 'Failed to fetch document data.');
        console.log(error);
      }
    );
  }

  getSentenceById(docId: string) {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    this.userService.getSentencesById(userId, token, docId).subscribe(
      (Response: any) => {
        if (Response.meta.sentences.length > 0) {
          this.sentenceData = Response.meta.sentences;

          this.corrections = 0;
          let validTruthCount = 0;
          let sumOfTruth = 0;
          this.allAssertions = [];
          for (let sentence of this.sentenceData) {
            if (sentence.truthValue == -1) {
              this.corrections++;
            } else {
              validTruthCount++;
              sumOfTruth += sentence.truthValue;
            }
            this.allAssertions.push(sentence.results.query);
          }
          this.truthMeter = sumOfTruth / validTruthCount;
          this.setGuageChart();
          this.getSyllogims();
        } else if (Response.meta.code == 1006) {
          console.log('No Sentences Found');
          this.sentenceData = {};
        } else {
          this.sentenceData = {};
          // this.openToaster(false, true, 'Failed to retrieve user history data.');
        }
      },
      (error: any) => {
        this.openToaster(
          false,
          true,
          'Failed to retrieve user Sentences data.'
        );
        console.log(error);
      }
    );
  }

  openToaster(isSuccess: boolean, isError: boolean, message: string) {
    if (isSuccess) {
      this.showSuccessToaster = true;
      this.showSuccessMsg = message;
    } else if (isError) {
      this.showErrorToaster = true;
      this.showErrorMsg = message;
    }

    setTimeout(() => {
      this.closeToaster();
    }, 10000);
  }

  closeToaster() {
    this.showErrorToaster = false;
    this.showErrorMsg = '';
    this.showSuccessToaster = false;
    this.showSuccessMsg = '';
  }

  // Method to determine the class based on truthvalue
  getClassForTruthValue(truthValue: number): string {
    if (truthValue === -1) {
      return 'correction';
    } else if (truthValue >= 0 && truthValue < 34) {
      return 'false';
    } else if (truthValue >= 34 && truthValue < 78) {
      return 'partially';
    } else if (truthValue >= 78) {
      return 'true';
    } else {
      return '';
    }
  }

  loadHtmlContentFromUrl(url: string): void {
    this.http.get(url, { responseType: 'text' }).subscribe((html: string) => {
      this.edtablehtmlContent =
        'In the evolving landscape of digital media, social media platforms wield unprecedented influence over public discourse and information flow. As platforms like Meta (formerly Facebook) have expanded their reach and significance, they have encountered increasing scrutiny regarding their content moderation practices. The sheer scale and complexity of content management on these platforms necessitate sophisticated governance frameworks capable of balancing freedom of expression with the need to curtail harmful content. In response to these challenges, Meta introduced the Oversight Board in 2020—a pioneering initiative aimed at providing an independent, external review of the company’s content moderation decisions. This case study delves into the establishment, function, and impact of Meta’s Oversight Board, scrutinizing how it signifies a notable shift towards formalized content governance structures and assessing its effectiveness in the broader context of industry practices. By exploring this initiative, the study aims to provide a nuanced understanding of the board’s role in shaping content moderation and its implications for digital governance.';
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(html);
      // After setting the content, add click listeners
      setTimeout(() => {
        this.addClickListenersToGuidElements();
      }, 0);
    });
  }

  ngOnDestroy() {
    // Remove all event listeners when the component is destroyed
    this.eventListeners.forEach((unregister) => unregister());
    this.eventListeners = []; // Clear the array after removal
  }

  // form = new FormGroup({
  //   editorContent: new FormControl(
  //      { value: jsonDoc, disabled: false },
  //     Validators.required()
  //   ),
  // });
}
