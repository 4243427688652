<div class="main_dashboard_section" [ngClass]="{'citation-page' : currentRoute == '/citations', 'new-version' : currentRoute == '/query'}">
  <!-- <div class="search-history-panel"
    *ngIf="currentRoute !== '/' && currentRoute !== '/cache-system' && currentRoute !== '/unauthorized' && currentRoute !== '/expired' && currentRoute !== '/plan-expired' && currentRoute !== '/search-results-view'">
    <app-left-history-panel></app-left-history-panel>
  </div> -->

  <div class="result-sec"><!-- download_pdf_init -->
    <div class="result-header-section" [ngClass]="{'home-header' : currentRoute == '/', 'results-view-header' : currentRoute == '/search-results-view'}"
      *ngIf="currentRoute !== '/cache-system' && currentRoute !== '/unauthorized' && currentRoute !== '/expired' && currentRoute !== '/plan-expired' ">
      <app-header></app-header>
    </div>

    <div class="right-content-section"
      [ngClass]="{'main-search-p' : currentRoute == '/', 'unauthorized-page' : currentRoute == '/unauthorized', 'cache-sys-page' : currentRoute == '/cache-system', 'search-resultsview' : currentRoute == '/search-results-view'}">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>